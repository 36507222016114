export const shipmentHeaders = [
  "dropoffAddress",
  "shipmentId",
  "pickupLocationName",
  "pickupAddress",
  "deliveryStatus",
  "receiverStatus",
  "paymentStatus",
  "paymentAmount",
  "vehicleRegistration",
  "updatedDate",
  "warehouseLocation",
  "unitCount",
  "dropoffScheduledTime",
]

export const deliveryStatusHeaders = ["completed", "failed", "out-for-delivery"]
