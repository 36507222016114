import { createSlice } from "@reduxjs/toolkit"

const shipmentSlice = createSlice({
  name: "shipment",
  initialState: {
    shipmentForDuplicate: null,
  },
  reducers: {
    setShipmentForDuplicate(state, action) {
      state.shipmentForDuplicate = action.payload
    },
  },
  extraReducers: {},
})

const shipmentReducer = shipmentSlice.reducer
export { shipmentReducer }
export const { setShipmentForDuplicate } = shipmentSlice.actions
